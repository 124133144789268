import { createIcon } from "@chakra-ui/icons";

export const DropdownIcon = createIcon({
  displayName: "more",
  viewBox: "0 0 16 9",
  path: <path d="m1 1 7 6 7-6" stroke="#7A7D84" strokeWidth="2" />,
  defaultProps: {
    fill: "none",
    width: 16,
    height: 9,
  },
});
