import React, { useContext, useState } from "react";
import { Button, Select, Wrap } from "@chakra-ui/react";
import { TenantContext } from "../shared/contexts/tenantContext";
import { Locale } from "../shared/types";
import { DropdownIcon } from "./icons/DropdownIcon";
import { useRouter } from "next/router";

interface LanguageSwitcherProps {
  style?: "select" | "buttons";
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  style = "select",
}) => {
  const { pathname, asPath, query, locale: currentLocale, push } = useRouter();
  const [loadingLocale, setLoadingLocale] = useState<string>(); // which locale is currently being loaded
  const tenantConfig = useContext(TenantContext);

  if (style === "select") {
    return (
      <Select
        icon={<DropdownIcon marginStart={4} />}
        defaultValue={currentLocale}
        onChange={(e) => {
          const newLocale = e.target.value;
          push({ pathname, query }, asPath, { locale: newLocale });
        }}
      >
        {tenantConfig.enabledPatientLocales
          .filter(
            (locale) => tenantConfig.name === "de" || locale !== Locale.de
          )
          .map((locale) => (
            <option key={locale} value={locale}>
              {withoutRegionalSuffix(locale).toUpperCase()}
            </option>
          ))}
      </Select>
    );
  }

  return (
    <Wrap>
      {tenantConfig.enabledPatientLocales
        .filter((locale) => tenantConfig.name === "de" || locale !== Locale.de)
        .map((locale) => (
          <Button
            key={locale}
            variant={currentLocale === locale ? "primary" : "secondary"}
            isLoading={locale === loadingLocale}
            onClick={async () => {
              setLoadingLocale(locale);
              await push({ pathname, query }, asPath, { locale });
              setLoadingLocale(undefined);
            }}
          >
            {withoutRegionalSuffix(locale).toUpperCase()}
          </Button>
        ))}
    </Wrap>
  );
};

const withoutRegionalSuffix = (locale: string) => locale.split("-")[0];
