import React, { useContext, useState } from "react";
import Link from "next/link";
import {
  type ButtonProps,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { WrapRight } from "./WrapRight";
import { useTranslations } from "../shared/i18n/localization";
import { type Doctor, Locale } from "../shared/types";
import { CrossBulletIcon } from "./icons/CrossBulletIcon";
import { getStartCaseURL } from "../shared/helpers/urls";
import { TenantContext } from "../shared/contexts/tenantContext";
import { doctorLanguages } from "../shared/helpers/doctors";
import { sendToGoogleTagManager } from "../shared/googleTagManager";
import parse from "html-react-parser";
import { useAnticipatedReturnDate } from "../shared/api/swr/data/useAnticipatedReturnDate";
import { useRouter } from "next/router";

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  return `${day}.${month}.${date.getFullYear()}`;
};

interface DoctorChoiceButtonProps extends ButtonProps {
  doctor: Doctor;
  text?: string;
  onClick?: () => void;
}

export const DoctorChoiceButton: React.FC<DoctorChoiceButtonProps> = ({
  doctor,
  text,
  onClick,
  ...buttonProps
}) => {
  const { translate, locale } = useTranslations();
  const tenantConfig = useContext(TenantContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const router = useRouter();

  const { data, isValidating } = useAnticipatedReturnDate({
    doctorId: doctor.id,
    triggerFetch,
    swrOptions: {},
  });

  const hasSameLanguage = doctorLanguages(doctor).includes(
    languageFromLocale(locale)
  );

  if (!doctor.is_available) {
    return (
      <>
        <Button
          onClick={() => {
            sendToGoogleTagManager({
              event: "absence_message_viewed",
            });
            onOpen();
            setTriggerFetch(true);
            if (onClick) onClick();
          }}
          {...buttonProps}
        >
          {text ?? translate("doctorProfile.start_case")}
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader tabIndex={0} marginTop={4}>
              <CrossBulletIcon />
              <Heading>{translate("doctor_unavailable_modal.heading")}</Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {isValidating ? (
                <Center>
                  <Spinner />
                </Center>
              ) : data?.next_available_time ? (
                parse(
                  translate("doctor_unavailable_modal.body", {
                    anticipated_return_date: formatDate(
                      data.next_available_time
                    ),
                  })
                )
              ) : (
                translate("doctor_unavailable_modal.no_return_date")
              )}
            </ModalBody>
            <ModalFooter>
              <WrapRight>
                <Button variant="quaternary" margin="11px" onClick={onClose}>
                  {translate("doctor_unavailable_modal.cancel")}
                </Button>
                <Button
                  margin="11px"
                  onClick={() => {
                    sendToGoogleTagManager({
                      event: "absence_message_confirmed",
                    });
                    if (router.pathname == "/doctors") {
                      onClose();
                    } else {
                      router.push("/doctors");
                    }
                  }}
                >
                  {translate("doctor_unavailable_modal.choose_another_doctor")}
                </Button>
              </WrapRight>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  if (hasSameLanguage) {
    return (
      <Link
        href={getStartCaseURL(tenantConfig.id, {
          doctor_id: parseInt(doctor.id),
          lang: locale,
        })}
      >
        <Button {...buttonProps} onClick={onClick}>
          {text ?? translate("doctorProfile.start_case")}
        </Button>
      </Link>
    );
  } else {
    return (
      <>
        <Button
          onClick={() => {
            onOpen();
            if (onClick) onClick();
          }}
          {...buttonProps}
        >
          {text ?? translate("doctorProfile.start_case")}
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader tabIndex={0} marginTop={4}>
              <CrossBulletIcon />
              <Heading>{translate("language_mismatch.heading")}</Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>{translate("language_mismatch.text")}</ModalBody>
            <ModalFooter>
              <WrapRight>
                <Button variant="quaternary" margin="11px" onClick={onClose}>
                  {translate("language_mismatch.cancel")}
                </Button>
                <Link
                  href={getStartCaseURL(tenantConfig.id, {
                    doctor_id: parseInt(doctor.id),
                    lang: locale,
                  })}
                >
                  <Button margin="11px">
                    {translate("language_mismatch.confirm")}
                  </Button>
                </Link>
              </WrapRight>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
};

const languageFromLocale = (locale: Locale) =>
  locale === Locale["de-ch"] ? Locale.de : locale;
