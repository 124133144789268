import { createIcon } from "@chakra-ui/icons";

export const CrossBulletIcon = createIcon({
  displayName: "CrossBulletIcon",
  viewBox: "0 0 21 21",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 0v7.875H0v5.25h7.875V21h5.25v-7.875H21v-5.25h-7.875V0h-5.25Z"
    />
  ),
  defaultProps: {
    fill: "primary.500",
    boxSize: { base: 5, xl: "28px" },
  },
});
