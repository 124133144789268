import type { IconProps } from "@chakra-ui/react";
import { useContext } from "react";
import { TenantContext } from "../../shared/contexts/tenantContext";

export const OnlineDoctorIcon: React.FC<IconProps> = ({
  width,
  height,
  color,
}) => {
  const tenantConfig = useContext(TenantContext);
  return (
    <tenantConfig.iconComponent
      width={width ?? "100%"}
      height={height ?? "width"}
      color={color}
    />
  );
};
