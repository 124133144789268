export function usePublicApiFetcher() {
  const fetcher = async (key: string) => {
    const response = await fetch(`${process.env.coreServiceBaseUrl}${key}`, {
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Error while fetching ${key}`);
    }

    return response.json();
  };

  return { fetcher };
}

export function flattenApiPages<Type>(pages: { data: Type[] }[]) {
  const result = [];
  for (const page of pages) {
    for (const item of page.data) {
      result.push(item);
    }
  }
  return result;
}
