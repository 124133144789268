export const sendToGoogleTagManager = (variables: object) => {
  try {
    // eslint-disable-next-line
    const dataLayer = (window as any)?.dataLayer;
    if (dataLayer) {
      dataLayer.push(variables);
    }
  } catch (error) {
    console.warn(error);
  }
};
