export enum ConsentScope {
  google_tag_manager = "google_tag_manager",
}

export const consentCookieDefinition = {
  name: "od_cookies_consent",
  path: "/",
  maxAge: 7 * 24 * 60 * 60,
};

export const getConsentCookieDomain = (domain: string) => {
  const segments = domain.split(".");
  return segments.length === 1 ? segments[0] : segments.slice(-2).join(".");
};

export const extractConsentScopesFromCookieValue = (
  value?: string
): Set<ConsentScope> => {
  if (!value) return new Set();
  return new Set(value.split("|") as ConsentScope[]);
};

export const getCookieValueFromConsentScopes = (
  scopes: ConsentScope[]
): string => {
  return scopes.join("|");
};

const matchingFuncs = {
  matchesName: (name: string) => {
    return (cookies: Set<string>) => {
      return cookies.has(name) ? [name] : [];
    };
  },
  startsWith: (prefix: string) => {
    return (cookies: Set<string>) => {
      return Array.from(cookies).filter((name) => name.startsWith(prefix));
    };
  },
};

interface CookieDefinition {
  getMatches: (cookies: Set<string>) => string[];
  path: string;
  consentScope: ConsentScope;
}

export const cookieDefinitions: CookieDefinition[] = (() => {
  return [
    {
      getMatches: matchingFuncs.matchesName("_gid"),
      path: "/",
      consentScope: ConsentScope.google_tag_manager,
    },
    {
      getMatches: matchingFuncs.startsWith("_ga"),
      path: "/",
      consentScope: ConsentScope.google_tag_manager,
    },
  ];
})();

interface Cookie {
  name: string;
  path: string;
}

export const getCookiesToDelete = (
  cookieNames: Set<string>,
  cookieDefinitions: CookieDefinition[],
  consentScopes: Set<ConsentScope>
) => {
  // delete all cookies matching definitions without consent
  const cookiesToDelete: Cookie[] = [];
  for (const cookieDefinition of cookieDefinitions) {
    if (consentScopes.has(cookieDefinition.consentScope)) continue;

    for (const cookieName of cookieDefinition.getMatches(cookieNames)) {
      cookiesToDelete.push({
        name: cookieName,
        path: cookieDefinition.path,
      });
    }
  }
  return cookiesToDelete;
};

export const werePreferencesAlreadySetWithBorlabs = (): boolean => {
  // Return whether cookie preferences have already been set from WordPress
  // via the cookie banner implemented with the Borlabs Cookie WP plugin.
  // If this function returns true, then the caller can decide to not show
  // the cookie banner, as the only cookie that is actually useful (GA)
  // has already been accepted or denied on WordPress by the user.
  try {
    const consents = borlabsCookieConsents();
    return (
      "essential" in consents &&
      Array.isArray(consents.essential) &&
      consents.essential.includes("borlabs-cookie")
    );
  } catch {
    return false;
  }
};

export const wereAnalyticsAlreadyAcceptedWithBorlabs = (): boolean => {
  // Return whether Google Analytics has already been accepted from WordPress
  // via the cookie banner implemented with the Borlabs Cookie WP plugin.
  // If this function returns true, then the caller can decide to set up
  // Google Tag Manager.
  try {
    const consents = borlabsCookieConsents();
    return (
      "marketing" in consents &&
      Array.isArray(consents.marketing) &&
      consents.marketing.includes("google-tag-manager")
    );
  } catch {
    return false;
  }
};

const borlabsCookieConsents = (): object => {
  // Return the Borlabs cookie consent values.
  try {
    const borlabsCookieString =
      document.cookie
        .split("; ")
        .find((row) => row.startsWith("borlabs-cookie="))
        ?.split("=")[1] || "";
    const borlabsCookie = JSON.parse(decodeURIComponent(borlabsCookieString));
    if (
      typeof borlabsCookie === "object" &&
      "consents" in borlabsCookie &&
      typeof borlabsCookie.consents === "object"
    ) {
      return borlabsCookie.consents;
    } else {
      return {};
    }
  } catch {
    return {};
  }
};
