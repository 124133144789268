import { sendToGoogleTagManager } from "../../shared/googleTagManager";

const dataLayerScriptContent = "window.dataLayer = window.dataLayer || [];";
const dataLayerScriptContentNoAds = `
    // Google Consent Mode
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('consent', 'default', {
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'wait_for_update': 1500,
    });
    dataLayer.push({'gtm.start': new Date().getTime(), 'event': 'gtm.js'});

    // Microsoft Ads Mode
    window.uetq = window.uetq || [];
    window.uetq.push('consent', 'default', {
        'ad_storage': 'denied'
    });
`;

const googleTagManagerScriptContent = (
  googleTagManagerId: string
) => `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagManagerId}');`;

const googleTagManagerNoscriptContent = (googleTagManagerId: string) =>
  `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

export const GoogleTagManagerHeadScript = ({
  googleTagManagerId,
}: {
  googleTagManagerId: string;
}) => (
  <script
    className="gtag-script"
    dangerouslySetInnerHTML={{
      __html: googleTagManagerScriptContent(googleTagManagerId),
    }}
  ></script>
);

export const GoogleTagManagerDataLayerHeadScript = () => (
  <script dangerouslySetInnerHTML={{ __html: dataLayerScriptContent }}></script>
);

export const GoogleTagManagerDataLayerHeadScriptNoAds = () => (
  <script
    dangerouslySetInnerHTML={{ __html: dataLayerScriptContentNoAds }}
  ></script>
);

export const GoogleTagManagerBodyIFrame = ({
  googleTagManagerId,
}: {
  googleTagManagerId: string;
}) => (
  <noscript
    className="gtag-script"
    dangerouslySetInnerHTML={{
      __html: googleTagManagerNoscriptContent(googleTagManagerId),
    }}
  ></noscript>
);

const injectGoogleTagManagerScripts = (googleTagManagerId: string) => {
  [
    {
      tagName: "noscript",
      content: googleTagManagerNoscriptContent(googleTagManagerId),
      parent: document.body,
    },
    {
      tagName: "script",
      content: googleTagManagerScriptContent(googleTagManagerId),
      parent: document.head,
    },
  ].forEach(({ tagName, content, parent }) => {
    const element = document.createElement(tagName);
    element.appendChild(document.createTextNode(content));
    element.classList.add("gtag-script");
    parent.append(element);
  });
};

export const renderGoogleTagManagerScripts = ({
  include,
  googleTagManagerIds,
}: {
  include: boolean;
  googleTagManagerIds: string[];
}) => {
  const included = document.getElementsByClassName("gtag-script").length > 0;
  if (include == included) return;
  if (include) {
    googleTagManagerIds.forEach((id) => injectGoogleTagManagerScripts(id));
    // Google Consent Mode
    sendToGoogleTagManager({
      event: "consent",
      default: {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
        wait_for_update: 1500,
      },
    });
    // Microsoft Ads Mode
    try {
      const uetq = (window as any)?.uetq;
      if (uetq) {
        uetq.push("consent", "default", {
          ad_storage: "granted",
        });
      }
    } catch (error) {
      console.warn(error);
    }
  } else {
    // Reloading the window will remove the script as well as deleting non
    // consented cookies.
    //
    // It is difficult to remove the script on the frontend, because, even if
    // they are removed from the DOM, their code might still be running.
    window.location.reload();
  }
};

export const getGoogleTagManagerIds = (configValue?: string) =>
  (configValue || "").split(",").filter(Boolean);
