import { useContext } from "react";
import { usePublicApiFetcher } from "../utils";
import { TenantContext } from "../../../contexts/tenantContext";
import useSWR, { BareFetcher, SWRConfiguration } from "swr";

type Data = { next_available_time: string };
type Error = any;

interface AnticipatedReturnDate {
  doctorId: string;
  triggerFetch: boolean;
  swrOptions: SWRConfiguration<Data, Error, BareFetcher<Data>>;
}

export const useAnticipatedReturnDate = (args: AnticipatedReturnDate) => {
  const tenantConfig = useContext(TenantContext);
  const { fetcher } = usePublicApiFetcher();
  const key = args.triggerFetch
    ? `tenants/${tenantConfig.id}/public/next_availability/${args.doctorId}`
    : undefined;
  const { data, error, isValidating } = useSWR<Data>(
    key,
    fetcher,
    args.swrOptions
  );

  return {
    data,
    error,
    isValidating,
  };
};
