import { useContext } from "react";
import { Flex, type FlexProps, Image, Link, Text } from "@chakra-ui/react";
import { TenantContext } from "../shared/contexts/tenantContext";
import { useTranslations } from "../shared/i18n/localization";
import { publicAssetUrl } from "../shared/helpers/urls";

export const Footer: React.FC<FlexProps> = (flexProps) => {
  const tenantConfig = useContext(TenantContext);
  const { translate, locale } = useTranslations();
  return (
    <Flex
      as="footer"
      flexDirection="column"
      color="monochrome.white"
      {...flexProps}
    >
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-around"
        alignItems="center"
        textAlign={{ base: "center", md: "left" }}
        backgroundColor="primary.500"
        padding={{ base: 5, md: 14 }}
      >
        <Flex flexDirection="column">
          <Text textStyle="p2" marginBottom={2}>
            {translate("links.legal")}
          </Text>
          {tenantConfig.links.legal.map((link) => (
            <Link
              key={link.name}
              href={`${link.url[locale]}`}
              marginBottom={4}
              style={{ textDecoration: "none" }}
              color="monochrome.white"
              fontWeight="bold"
            >
              {translate(link.name)}
            </Link>
          ))}
        </Flex>
        <Flex flexDirection="column" marginTop={{ base: 7, md: 0 }}>
          <Text>{tenantConfig.address.companyName}</Text>
          <Text>{translate(tenantConfig.address.line1)}</Text>
          <Text>{translate(tenantConfig.address.line2)}</Text>
          <Flex justifyContent="space-around" alignItems="center" marginTop={4}>
            {tenantConfig.links.social.map((link) => (
              <Link
                key={link.name}
                href={link.url}
                title={link.name}
                isExternal
                color="monochrome.white"
                fontSize={{ base: "25px", md: "30px" }}
              >
                <link.iconComponent />
              </Link>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        backgroundColor="primary.600"
        justifyContent="center"
        alignItems="center"
        padding={5}
      >
        <Image
          src={publicAssetUrl("/logo_CE.svg")}
          alt={translate("ce_logo")}
          width={30}
          height={30}
        />
        <Text textStyle="p2" fontWeight="bold" marginLeft={3}>
          {translate("ce_logo")}
        </Text>
      </Flex>
    </Flex>
  );
};
