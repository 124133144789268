import React, { Children } from "react";
import { Flex, type FlexProps } from "@chakra-ui/react";

/**
 * React component similar to Chakra UI's Wrap component, but that
 * always aligns wrapped components to the right regardless of
 * whether the current locale reads from left to right or viceversa.
 *
 * It intentionally sets the text direction to RTL (right to left),
 * lays out its children in reverse order (so that they show up
 * left to right when they haven't wrapped yet), and sets the wrap
 * property to 'wrap-reverse' so that the children (which are laid
 * out in reverse order) are shown from top to bottom and not
 * viceversa.
 */
export const WrapRight: React.FC<FlexProps> = ({ children }) => (
  <Flex justify="space-between" wrap="wrap-reverse" dir="rtl">
    {Children.toArray(children).reverse()}
  </Flex>
);
