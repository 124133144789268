import type { Doctor } from "../types";

export const doctorFullName = (doctor: Doctor) =>
  doctor.personal_information?.title
    ? `${doctor.personal_information?.title} ${doctor.personal_information?.forename} ${doctor.personal_information?.surname}`
    : `${doctor.personal_information?.forename} ${doctor.personal_information?.surname}`;

export const doctorLanguages = (doctor: Doctor) =>
  doctor.correspondence_language
    ? Array.from(
        new Set(
          doctor.additional_languages?.concat([doctor.correspondence_language])
        )
      )
    : (doctor.additional_languages ?? []);

export const doctorWorkplace = (doctor: Doctor) =>
  doctor.workplaces.length > 0 ? doctor.workplaces[0] : undefined;

export function doctorStreet(doctor: Doctor) {
  const workplace = doctorWorkplace(doctor);
  return workplace
    ? `${workplace.address.line1} ${workplace.address.line2}`
    : undefined;
}
